import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllByPlaceholderText } from '@testing-library/dom'
import { API_BASEURL } from '../../config'

export const createCart = createAsyncThunk(
    'cart/create',
    async () => {
        let key = window.localStorage.getItem('cart_key')
        if(!key) {
            const createCart = await fetch(new URL('api/cart', API_BASEURL), {
                method: 'POST',
            })

            const json = await createCart.json()
            key = json.key
            window.localStorage.setItem('cart_key', key)
        }

        return key
    }
)

export const addItem = createAsyncThunk(
    'cart/addItem',
    async ({cart, variantId}) => {

        const response = await fetch(new URL(`api/cart/${cart}/items`, API_BASEURL), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({variant_id: variantId}),
        })

        return response.json()
    }
)

export const updateQuantity = createAsyncThunk(
    'cart/updateQuantity',
    async ({cart, item, quantity}) => {
        const response = await fetch(new URL(`api/cart/${cart}/items/${item}`, API_BASEURL), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                new_quantity: quantity,
            }),
        })

        return response.json()
    }
)

export const removeItem = createAsyncThunk(
    'cart/removeItem',
    async ({cart, item}) => {
        const response = await fetch(new URL(`api/cart/${cart}/items/${item}`, API_BASEURL), {
            method: 'DELETE',
        })

        return response.json()
    }
)

export const getContents = createAsyncThunk(
    'cart/getContents',
    async (key) => {
        const response = await fetch(new URL('api/cart/' + key, API_BASEURL))
        return response.json()
    }
)


export const cartSlice  = createSlice({
    name: 'cart',
    initialState: {
        changed: false,
        loaded: false,
        uiOpen: false,
        key: '',
        items: [],

        quantity: 0,
        rows: 0,
        subtotal: 0,
    },
    reducers: {
        setId: (state, action) => {
            state.key = action.payload
        },
        toggleVisibility: (state) => {
            state.uiOpen = !state.uiOpen
        },
        addItem: (state, action) => {
            state.changed = true
            console.log(action.payload)
        },
    },
    extraReducers: (builder) => {
        const setItems = (state, {payload}) => {
            state.items = payload.items
            state.rows = payload.items.length
            state.quantity = payload.items.reduce((prev, curr) => prev + curr.quantity, 0)
            state.subtotal = payload.items.reduce((prev, curr) => prev + parseFloat(curr.line_total), 0)

            if(state.loaded === false) {
                state.loaded = true
            }
        }

        builder.addCase(addItem.fulfilled, setItems)
        builder.addCase(removeItem.fulfilled, setItems)
        builder.addCase(getContents.fulfilled, setItems)
        builder.addCase(updateQuantity.fulfilled, setItems)

        builder.addCase(createCart.fulfilled, (state, {payload}) => {
            state.key = payload
        })
    },
})

export const { setId, toggleVisibility } = cartSlice.actions

export default cartSlice.reducer
