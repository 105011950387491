import { configureStore } from '@reduxjs/toolkit'

import cartReducer from './features/cartSlice'
import variantSelectorReducer from './features/variantSelectorSlice'
import productSlice from './features/productSlice'

export default configureStore({
  reducer: {
      cart: cartReducer,
      variantSelector: variantSelectorReducer,
      product: productSlice,
  },
})
