import ProductOption from './ProductOption'
import { useEffect } from 'react'
import AddToCart from './AddToCart'
import { setVariants, setOption } from './store/features/variantSelectorSlice'
import { useDispatch, useSelector } from 'react-redux'

function ProductVariants(props) {
    const dispatch = useDispatch()

    const variant = useSelector(state => state.variantSelector.variant)

    useEffect(() => {
        dispatch(setVariants(props.variants))
    }, [])

    const onOptionChange = (position, value) => {
        dispatch(setOption({position, value}))
    }

    const options = props.options.map((option, index) => {
        return (<ProductOption key={index} option={option} onChange={onOptionChange}></ProductOption>)        
    })

    return (
        <>
        <div className="ProductVariants">
            {options}
        </div>
        <AddToCart></AddToCart>
        </>
    )
}

export default ProductVariants
