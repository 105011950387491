import './ProductOption.css'
import { useState } from 'react'

function ProductOptions(props) {

    const defaultValue = props.option.values[0]
    const position = props.option.position

    const [value, setValue] = useState(defaultValue)
    const name = props.option.name.toLowerCase().replace(' ', '-')

    const onChange = (value) => {
        setValue(value)
        props.onChange(position, value)
    }

    const inputs = props.option.values.map((val, index) => {
        const id = `ProductOption_${name}_${val}`
        return (
            <label key={index} htmlFor={id} className="ProductOption-label">
                <input className="ProductOption-input"
                    type="radio"
                    value={val}
                    id={id}
                    name={props.option.name}
                    checked={val === value}
                    onChange={(e) => onChange(e.target.value)}/>

                <span className="ProductOption-proxy-input"></span>
                {val}
            </label>
        )
    })

    return (
        <div className="ProductOption">
            <div className="ProductOption-title">{props.option.name}</div>
            <div className="ProductOption-inputs">{inputs}</div>
        </div>
    )
}

export default ProductOptions
