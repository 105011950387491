import './ProductDetail.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 

import ProductAttribute from './ProductAttribute'
import ProductVariants from './ProductVariants'
import ProductImages from './ProductImages'
import Klarna from './Klarna'
import { load } from './store/features/productSlice'


function ProductDetail() {
    const dispatch = useDispatch()

    const product = useSelector(state => state.product.product)
    const loaded = useSelector(state => state.product.loaded)

    const setHtml = (safeHtml) => {
        return {
            __html: safeHtml,
        }
    }

    useEffect(() => {
        // The product slug or ID would be retrieved dynamically
        dispatch(load('product-slug'))
    }, [])

    const productAttributes = []
    product.attributes.filter(attr => attr.key !== 'Ingredients').forEach((attribute, i) => {
        const action = attribute.key === 'Key ingredients'
            ? () => {}
            : null

        productAttributes.push(<ProductAttribute key={i} attribute={attribute.key} value={attribute.value} action={action}></ProductAttribute>)
    })

    return (
        <div className="ProductDetail">
            {(loaded ?
            <>
                <div className="ProductImage">
                    <ProductImages image={product.image} images={product.images}></ProductImages>
                </div>

                <div className="ProductDetail-content">
                    <h1>{product.title}</h1>
                    <div dangerouslySetInnerHTML={setHtml(product.description)}></div>
                    <dl className="ProductDetail-attributes">{productAttributes}</dl>
                    <ProductVariants variants={product.variants} options={product.options}></ProductVariants>
                    <Klarna></Klarna>
                </div>
            </>
            : '')}

        </div>
    )
}

export default ProductDetail
