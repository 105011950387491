import './AddToCart.css'

import { useDispatch, useSelector } from 'react-redux'
import { addItem } from './store/features/cartSlice'

function AddToCart(props) {
    const dispatch = useDispatch()

    const variant = useSelector(state => state.variantSelector.variant)
    const cart = useSelector(state => state.cart.key)

    const handleClick = () => {
        const payload = {
            cart,
            variantId: variant.id,
        }
        dispatch(addItem(payload))
    }

    return (
        <div className="AddToCart">
            <button className="AddToCart-button" onClick={handleClick}>Add to your cart - £{variant.price}</button>
        </div>
    )
}

export default AddToCart
