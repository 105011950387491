import { createSlice } from '@reduxjs/toolkit'

const findVariant = (variants, userOptions) => {
    return variants.find(variant => {
        const options = [variant.option1, variant.option2, variant.option3].filter(option => option !== '')
        const selected = [...userOptions].splice(0, options.length)
        return options.toString() === selected.toString()
    })
}

export const variantSelectorSlice = createSlice({
    name: 'variantSelector',
    initialState: {
        variant: {},
        variants: [],
        options: ['', '', ''],
    },
    reducers: {
        setVariant: (state, action) => {
            state.variant = action.payload
        },
        setVariants: (state, action) => {
            const defaultVariant = action.payload[0]
            const defaultOptions = [defaultVariant.option1, defaultVariant.option2, defaultVariant.option3]

            state.variants = action.payload
            state.variant = defaultVariant
            state.options = defaultOptions
        },
        setSelectedId: (state, action) => {
            state.selected = action.payload
            state.variant = state.variants.find(variant => variant.id === action.payload)
        },
        setOption: (state, action) => {
            state.options[action.payload.position - 1] = action.payload.value
            state.variant = findVariant(state.variants, state.options)
            state.selected = state.variant.id
        },
    }
})

export const { setVariant, setVariants, setSelectedId, setOption } = variantSelectorSlice.actions

export default variantSelectorSlice.reducer
