import './Cart.css'
import CartItem from './CartItem'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleVisibility, getContents, createCart } from './store/features/cartSlice'

function Cart() {
    const visible = useSelector((state) => state.cart.uiOpen)
    const items = useSelector((state) => state.cart.items)
    const key = useSelector((state) => state.cart.key)
    const subtotal = useSelector((state) => state.cart.subtotal)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(createCart())
    }, []);

    useEffect(() => {
        if(key) {
            dispatch(getContents(key))
        }
    }, [key])

    const className = [
        'Cart',
        'Cart__' + (visible ? 'visible' : 'hidden'),
    ]

    return (
        <div className={className.join(' ')}>
            <table className="CartItems">
                <thead>
                    <tr>
                        <th className="CartItems-heading__cart">Cart</th>
                        <th>Size</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {items.map((item, i) => <CartItem key={i} item={item}></CartItem>)}
                </tbody>
            </table>

            <button className="Cart-close" onClick={() => dispatch(toggleVisibility())}>
                <svg className="Icon" role="img" viewBox="0 0 50 50"><g><polygon points="50,5 45,0 25,20 5,0 0,5 20,25 0,45 5,50 25,30 45,50 50,45 30,25"></polygon></g></svg>
            </button>

            <div className="Cart-information">
                <div className="Cart-left">
                    <p>Shipping from the United Kingdom.</p>
                </div>
                <div className="Cart-right">
                    <div>Enjoy complimentary shipping on all orders.</div>
                    <div className="Cart-subtotal">
                        <div className="Cart-subtotal__title">Subtotal (Tax Incl.)</div>
                        <div className="Cart-subtotal__amount">£{subtotal.toFixed(2)}</div>
                    </div>
                    <button className="Cart-checkout-btn">Checkout</button>
                </div>
            </div>
        </div>
    )
}

export default Cart
