import './App.css';
import Notice from './Notice'
import Header from './Header'
import ProductDetail from './ProductDetail'
import Cart from './Cart'

function App() {
  return (
    <div className="App">
      <Cart></Cart>
      <main>
        <Notice></Notice>
        <Header></Header>
        <ProductDetail></ProductDetail>
      </main>
    </div>
  );
}

export default App;
