import './Klarna.css'

function Klarna() {

    return (
        <div className="Klarna">Pay in 30 days with <span className="Klarna-brand">Klarna</span>. No fees. <a href="#">Learn more</a></div>
    )
}

export default Klarna
