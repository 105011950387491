import './ProductImages.css'

function ProductImages(props) {
    return (
        <div className="ProductImages">
            <img alt="Test" src={props.image.src}/>
        </div>
    )
}

export default ProductImages
