import './Notice.css'

function Notice(props) {

    return (
        <div className="Notice">
            <button className="Notice-text Notice-text__compact">Express delivery now available.</button>
            <button className="Notice-text Notice-text__full">Enjoy complimentary carbon neutral shipping on all orders. Express delivery now available.</button>
        </div>
    )
}

export default Notice
