import { updateQuantity, removeItem } from './store/features/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

function CartItem(props) {
    const dispatch = useDispatch()

    const { item } = props
    const cart = useSelector(state => state.cart.key)
    const [quantity, setQuantity] = useState(0)

    useEffect(() => {
        setQuantity(item.quantity)
    }, [item.quantity])
    
    const onQuantityChange = (e) => {
        setQuantity(e.target.value)
        dispatch(updateQuantity({
            cart,
            item: item.uuid,
            quantity: e.target.value,
        }))
    }

    const onRemoveItem = () => {
        dispatch(removeItem({
            cart,
            item: item.uuid,
        }))
    }

    return (
        <tr className="CartItem">
            <td className="CartItem-title">{item.name}</td>
            <td>{item.option1}</td>
            <td className="CartItem-qty">
                <select onChange={onQuantityChange} value={quantity}>
                    {([1,2,3,4,5].map(n =>
                        <option key={n}>{n}</option>
                    ))}
                </select>
                <button className="CartItem-remove" onClick={onRemoveItem}>Remove</button>
            </td>
            <td className="CartItem-price">£{item.line_total}</td>
        </tr>
    )
}

export default CartItem
