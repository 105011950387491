import './ProductAttribute.css'
import { Fragment } from 'react'

function ProductAttribute(props) {

    const actionButton = <button className="ProductAttribute-action">
        <svg focusable="false" height="22" role="img" tabIndex="-1" viewBox="0 0 50 50" width="22">
            <g><circle cx="25" cy="25" r="22" fill="none" className="Icon-module_circle__4IIx2"></circle>
            <polygon points="26.2,15.2 23.8,15.2 23.8,23.9 15,23.9 15,26.4 23.8,26.4 23.8,35.1 26.2,35.1 26.2,26.4 35,26.4 35,23.9 26.2,23.9" className="Icon-module_plus__2RJbo"></polygon>
            </g></svg>
    </button>

    return (
        <Fragment>
        <dt className="ProductAttribute-heading">{props.attribute} {props.action ? actionButton : ''}</dt>
        <dl className="ProductAttribute-detail">
            {props.value}
        </dl>
        </Fragment>
    )
}

export default ProductAttribute
