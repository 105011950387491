import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_BASEURL } from '../../config'

export const load = createAsyncThunk(
    'product/load',
    async (slug) => {
        const response = await fetch(new URL(`api/products/${slug}`, API_BASEURL))
        return response.json()
    }
)

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        loaded: false,
        product: {
            variants: [],
            attributes: [],
            image: {},
            options: [],
        },
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, {payload}) => {
            state.product = payload.product
            state.loaded = true
        })
    }
})

// export const {  } = productSlice.actions

export default productSlice.reducer
