import './Header.css'
import { useSelector, useDispatch } from 'react-redux'
import { toggleVisibility } from './store/features/cartSlice'
import Logo from './images/logo.js'

function Header() {
    const dispatch = useDispatch()
    const cartItems = useSelector((state) => state.cart.items)
    const cartQuantity = useSelector(state => state.cart.quantity)

    const cartButton = (
        <button onClick={() => dispatch(toggleVisibility())}>Cart {cartItems.length ? (<span>{cartQuantity}</span>) : ''}</button>
    )

    return (
        <header>
            <nav className="GlobalNavigation-desktop">
                <ul className="GlobalNavigation-left">
                    <li className="GlobalNavigation-brand">
                        <Logo/>
                    </li>
                    <li className="GlobalNavigation-shop-btn"><a href="#">Shop</a></li>

                    <li className="GlobalNavigation__hidden_md"><a href="#">Skin Care</a></li>
                    <li className="GlobalNavigation__hidden_md"><a href="#">Body &amp; Hand</a></li>
                    <li className="GlobalNavigation__hidden_md"><a href="#">Hair</a></li>
                    <li className="GlobalNavigation__hidden_md"><a href="#">Fragrance</a></li>
                    <li className="GlobalNavigation__hidden_md"><a href="#">Home</a></li>
                    <li className="GlobalNavigation__hidden_md"><a href="#">Kits &amp; Travel</a></li>
                    <li className="GlobalNavigation__hidden_md"><a href="#">Gifts</a></li>
                    <li><a href="#">Read</a></li>
                    <li><a href="#">Stores</a></li>
                </ul>

                <ul className="">
                    <li><a href="#">Login</a></li>
                    <li>{cartButton}</li>
                </ul>
            </nav>
        </header>
    )
}

export default Header
